// If you want to override variables do it here
@import "variables";
$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
.ag-theme-alpine {
  --ag-header-background-color: red;
  --ag-font-size: 16px;
  --ag-font-family: "roboto";
  --ag-header-foreground-color: white;
  --ag-borders: none;
  --ag-borders-row: 1px;
  --ag-row-border-color: #d8dbe0;
  --ag-background-color: transparent;
  --ag-row-hover-color: rgba(0, 0, 21, 0.075);
  --ag-grid-size: 10px;
  --ag-list-item-height: 40px;
  --ag-header-height: 50px;
}
.ag-row-odd {
  background-color: transparent !important;
}
.ag-theme-alpine .ag-header {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.ag-theme-alpine .ag-row {
  z-index: 1;
  overflow: visible;
  width: 100% !important;
}
.ag-theme-alpine .ag-cell {
  z-index: 1;
  overflow: visible;
  position: unset;
}
.ag-theme-alpine .ag-header-cell {
  position: unset;
}
.ag-header-container {
  width: 100% !important;
}
.ag-header-row {
  width: 100% !important;
}

body {
  font-family: "Roboto";
  .text_link {
    text-decoration: none;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
@media (min-width: 1200px) {
  .csvTable .modal-xl {
    max-width: 1200px;
  }
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  background-color: white !important;
  top: -5px;
  opacity: 1 !important;
  left: 20px;
  background: transparent;
  height: auto;
  margin-bottom: 0;
  border: 0;
}
.form-floating .form-control:focus {
  outline: none !important;
  border-color: #dadada !important;
  box-shadow: none !important;
}
.floating-password-input .input-group .form-floating {
  width: 100%;
}
.floating-password-input .input-group .form-floating .form-control {
  padding-right: 40px !important;
}
.floating-password-input .input-group {
  position: relative;
  width: 100%;
}
.floating-password-input .input-group .input-group-text {
  position: absolute;
  top: calc(50% - 8px);
  right: 16px;
  padding: 0;
}
.form-floating > .form-control {
  padding: 15px !important;
}
.form-floating > .form-control:not(:placeholder-shown) {
  padding: 15px !important;
}
.form-floating > label {
  padding: 6px 10px !important;
  top: 10px;
  margin: 0;
  height: auto;
}
@media (min-width: 1200px) {
  h4 {
    font-size: 18px !important;
  }
  // .form-control,
  .form-select {
    height: 40px !important;
  }
}

.ag-root-wrapper-body.ag-layout-normal {
  height: 100% !important;
}

.disable-input {
  opacity: 0.6;
}

.image-pop-modal .modal-content {
  width: auto !important;
  overflow-y: auto;
  height: 100vh;
  background-color: transparent !important;
  border: 0 !important;
}
.image-pop-modal .modal-body {
  display: flex;
  align-items: center;
  padding: 0;
}
.image-pop-modal .modal-dialog {
  max-width: calc(100vw - 10%);
  max-height: calc(100vh - 10%);
  justify-content: center;
}

.customer-pagination .pagination {
  max-width: 400px;
  width: 100%;
  display: inline-flex;
}
